//Import contact.module.css for Styling
import styles from './contact.module.css'


const Contact = () => {
    return(
        <h1 className={styles.contactHeading}>This Contact Us Page</h1>
    )
}

export default Contact