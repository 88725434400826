
import './App.css';
import { Outlet } from 'react-router-dom';

//Components Import Here
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      
      <main>
      <h1>Welcome To React Router DOM</h1>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
