import {Link, NavLink} from 'react-router-dom';
import './Navbar.css';

const Navbar = () =>{
    return(
        <ul>
            <li><NavLink to='/'>Home</NavLink></li>
            <li><NavLink to='/About'>About Us</NavLink></li>
            <li><NavLink to='/Testimonials'>Testimonials</NavLink></li>
            <li><NavLink to='/Contact'>Contact Us</NavLink></li>
        </ul>
    )
}

export default Navbar